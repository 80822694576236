import React from "react";
import styled from "styled-components/macro";
import Circle from "../images/brushcircle.png"
import Logo from "../images/LogoWhite.png"

const Background = styled.main`
width:100%;
height:100vh;

`;
const Box = styled.div`
background-image: url(${Circle});
width: 100%;
height: 100%;
background-repeat: no-repeat;
background-position: center;
`;

const LogoBox = styled.div`
background-image: url(${Logo});
width: 100%;
height: 100%;
background-repeat: no-repeat;
background-position: center;
`;

const Text = styled.h1`
color: #434141;
    position: absolute;
    top: 10px;
    left: 20px;
`;

export const Home = () => {
  return (

    <Background>
      <Box>
        <LogoBox></LogoBox>
       
      </Box>
      <Text>Mindfulness molnet.. Kommer snart! </Text>
    </Background>
  )
};
