import React from "react";
import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";
import { Home } from "./pages/Home";
import "./App.css";

export const AppRoutes = () => {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
  ]);
  return routes;
};
export const App = () => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};
